import {connect} from 'react-redux';
import {
	getCinema,
	getAddresses,
	getContactsPhones,
	getIsSeveralCinemasInCity,
} from 'app/selectors/AppSelectors';
import Contacts from 'app/components/contacts/Contacts';
import { Cinema, Phone } from 'app/types/common';

const mapStateToProps = (state, {hasTitle}) => ({
	hasTitle,
	cinema: getCinema(state),
	phones: getContactsPhones(state),
	addresses: getAddresses(state),
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
});

export type ContactsProps = ReturnType<typeof mapStateToProps> & {
	cinema: Cinema,
	addresses: {address: string, place: string, maps: string},
	phones: Phone[],
	isSeveralCinemasInCity: boolean,
	hasTitle?: boolean,
}

export default connect(mapStateToProps)(Contacts);