import { connect } from 'react-redux';
import {
	getAppData,
	getContactsRequest,
	getAboutCinemaRequest,
	setIsMobile,
	showPopup,
	hidePopup,
	getFondKinoAppData,
	updateCurrentCinema,
	setCountUnreadNotifications, getCinemaSettings,
	resetCurrentCinemaInfo, setCurrentCityId, setIsUserAuth, setIsWidgetInKioskMode,
} from 'app/actions/AppActions';
import { getAdvertisements, resetAdvertisements } from 'app/actions/AdvertisementsActions';
import { getPromos, resetPromos } from 'app/actions/PromoActions';
import { getNews, resetNews } from 'app/actions/NewsActions';
import { getCustomPagesRequest, resetPages } from 'app/actions/MenuActions';
import AppComponent from 'app/components/AppComponent';
import {
	isFetching,
	getCinema,
	getMenuLinks,
	getSoonPageIsEnabled,
	getIsSemiblind,
	getCurrentCityId,
	getIsAllPromosEnabled,
	isMobileSelector,
	isSmartBannerShown,
	hasAboutCinema,
	getAlert,
	getCurrentCinemaId,
	getIsSeveralCinemasInCity,
	getBranding,
	getIsMonitor,
	getSectionDisplaySettingsForCity,
	getIsKinokassa, getCurrentCinemas,
	getAllCities, getRoutePrefix,
	getWithNewDesign,
} from 'app/selectors/AppSelectors';
import { getCustomPagesRoutes } from 'app/selectors/MenuSelectors';
import { getAdvertisementsInfo, hasAdvertisementsList } from 'app/selectors/AdvertisementsSelectors';
import { getOffset } from 'app/selectors/NewsSelectors';
import { hasProCultureSeanses } from 'app/selectors/AfishaSelectors';
import { getQualifiers } from 'app/actions/AfishaActions';
import { ReactNode } from 'react';

const mapStateToProps = state => ({
	alert: getAlert(state),
	cinema: getCinema(state),
	cinemaId: getCurrentCinemaId(state),
	cities: getAllCities(state),
	hasAboutCinema: hasAboutCinema(state),
	isFetching: isFetching(state),
	isMobile: isMobileSelector(state),
	ads: getAdvertisementsInfo(state),
	soonPageIsEnabled: getSoonPageIsEnabled(state),
	isSmartBannerShown: isSmartBannerShown(state),
	menuLinks: getMenuLinks(state),
	isSemiblind: getIsSemiblind(state),
	customPagesRoutes: getCustomPagesRoutes(state),
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
	currentCinemas: getCurrentCinemas(state),
	isKinokassa: getIsKinokassa(state),
	cityId: getCurrentCityId(state),
	offsetNews: getOffset(state),
	isAllPromosEnabled: getIsAllPromosEnabled(state),
	branding: getBranding(state),
	isMonitor: getIsMonitor(state),
	sectionDisplaySettings: getSectionDisplaySettingsForCity(state),
	prefix: getRoutePrefix(state),
	hasProCultureSeanses: hasProCultureSeanses(state),
	withNewDesign: getWithNewDesign(state),
	hasAdvertisementsList: hasAdvertisementsList(state),
});

const mapDispatchToProps = {
	getAboutCinemaRequest,
	getAdvertisements,
	resetAdvertisements,
	getAppData,
	getContactsRequest,
	handleHidePopup: hidePopup,
	setIsMobile,
	showPopup,
	getFondKinoAppData,
	getCustomPagesRequest,
	updateCurrentCinema,
	resetCurrentCinemaInfo,
	resetPages,
	getPromos,
	resetPromos,
	getNews,
	resetNews,
	setCurrentCityId,
	setIsUserAuth,
	setIsWidgetInKioskMode,
	getQualifiers,
	setCountUnreadNotifications,
	getCinemaSettings,
};

export type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {
	currentRoutes: ReactNode,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
