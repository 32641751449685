import React, {lazy} from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import classNames from 'classnames';

import Menu from 'app/containers/MenuContainer';
import Header from 'app/containers/HeaderContainer';
import FondKinoHeader from 'app/containers/FondKinoHeaderContainer';
import FooterContainer from 'app/containers/FooterContainer';
import PopupContainer from 'app/containers/PopupContainer';
import SmartBannerContainer from 'app/containers/SmartBannerContainer';
import ViewContainer from 'app/containers/ViewContainer';
import {CinemaPropType, MOBILE_MAX_SIZE, __DEV__, TABLET_MAX_SIZE} from 'app/constants/AppConstants';
import {isMobile as checkIsMobile, isFondKino} from 'app/selectors/AppSelectors';
import {formatMoment, loadComponent, setYaReachGoal} from 'app/selectors/Helpers';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import {VREMYAKINO_CINEMA_ID} from 'app/constants/CinemaConstants';
import {AppRoutes} from 'app/components/AppRoutes';
import isNumeric from 'validator/es/lib/isNumeric';
import {getAnalyticsRequest, appendYaBase} from 'app/actions/AnalyticsActions';
import Refund from 'app/containers/ResendEmailPopupContainer';

const Advertisements = loadComponent(lazy(() => import('app/containers/AdvertisementsContainer')));

const isPreview = window.location.search === '?preview';
const pathname = window.location.pathname;
const isRefund = pathname.includes('/refund');

export default class AppComponent extends React.Component {
	static propTypes = {
		alert: PropTypes.shape({
			title: PropTypes.string,
			subtitle: PropTypes.string
		}),
		cinema: CinemaPropType.isRequired,
		cinemaId: PropTypes.number,
		cityId: PropTypes.number,
		getAboutCinemaRequest: PropTypes.func.isRequired,
		getAdvertisements: PropTypes.func.isRequired,
		resetAdvertisements: PropTypes.func.isRequired,
		getAppData: PropTypes.func.isRequired,
		getContactsRequest: PropTypes.func.isRequired,
		hasAboutCinema: PropTypes.bool.isRequired,
		handleHidePopup: PropTypes.func.isRequired,
		setCurrentCityId: PropTypes.func.isRequired,
		isMobile: PropTypes.bool.isRequired,
		isSmartBannerShown: PropTypes.bool.isRequired,
		ads: PropTypes.arrayOf(PropTypes.object).isRequired,
		menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
		setIsMobile: PropTypes.func.isRequired,
		showPopup: PropTypes.func.isRequired,
		isSemiblind: PropTypes.bool.isRequired,
		isSeveralCinemasInCity: PropTypes.bool.isRequired,
		currentCinemas: PropTypes.arrayOf(CinemaPropType).isRequired,
		isKinokassa: PropTypes.bool.isRequired,
		getFondKinoAppData: PropTypes.func.isRequired,
		updateCurrentCinema: PropTypes.func.isRequired,
		getCustomPagesRequest: PropTypes.func.isRequired,
		resetCurrentCinemaInfo: PropTypes.func.isRequired,
		resetPages: PropTypes.func.isRequired,
		getPromos: PropTypes.func.isRequired,
		resetPromos: PropTypes.func.isRequired,
		getNews: PropTypes.func.isRequired,
		resetNews: PropTypes.func.isRequired,
		offsetNews: PropTypes.number.isRequired,
		isMonitor: PropTypes.bool.isRequired,
		setIsUserAuth: PropTypes.func.isRequired,
		setIsWidgetInKioskMode: PropTypes.func.isRequired,
		branding: PropTypes.shape({
			bg_color: PropTypes.string,
			enabled: PropTypes.bool,
			img_url: PropTypes.string,
			link: PropTypes.string,
			new_tab: PropTypes.bool
		}),
		getQualifiers: PropTypes.func.isRequired,
		setCountUnreadNotifications: PropTypes.func.isRequired,
		getCinemaSettings: PropTypes.func.isRequired,
		withNewDesign: PropTypes.bool.isRequired,
		hasAdvertisementsList: PropTypes.bool.isRequired,
	}

	state = {
		windowWidth: window.innerWidth
	};

	UNSAFE_componentWillMount() {
		const {
			getAppData, getContactsRequest,
			getAdvertisements, getAboutCinemaRequest,
			cinemaId, getFondKinoAppData,
			getCustomPagesRequest, getPromos,
			getNews, cityId, offsetNews,
			setCurrentCityId, isKinokassa
		} = this.props;
		const pathCityId = window.location.pathname.split('/')[1];

		if (pathCityId && isNumeric(pathCityId)) {
			setCurrentCityId(+pathCityId);
		}

		if (!__DEV__ && !isKinokassa) {
			appendYaBase();
		}

		if (!cinemaId) {
			getFondKinoAppData();
			getPromos(null, cityId);
			getNews(cinemaId, cityId, offsetNews);
			getCustomPagesRequest(null, cityId);

			if (!isKinokassa) {
				getAdvertisements(null, cityId);
			}

			return;
		}

		getAppData(cinemaId);
		getCustomPagesRequest(cinemaId);
		getContactsRequest(cinemaId);
		getAboutCinemaRequest(cinemaId);

		if (!isKinokassa) {
			getAdvertisements(cinemaId);
		}
	}

	componentDidMount() {
		const {setIsUserAuth, setIsWidgetInKioskMode, getQualifiers, cityId} = this.props;
		const isUserAuth = localStorage.getItem('isUserAuth');
		const isWidgetInKioskMode = localStorage.getItem('isWidgetInKioskMode');

		const today = formatMoment(moment());

		getQualifiers(today, cityId);
		setIsUserAuth(JSON.parse(isUserAuth));
		setIsWidgetInKioskMode(JSON.parse(isWidgetInKioskMode));

		window.addEventListener('message', this.setAuthStatus);
		window.addEventListener('message', this.setCountUnreadNotifications);
		window.addEventListener('message', this.setWidgetKioskModeStatus);
		this.props.setIsMobile(checkIsMobile());
		window.addEventListener('resize', this.checkScreenSize);
		window.addEventListener('message', this.handleMessage);
	}

	componentDidUpdate(prevProps) {
		const {
			alert,
			cinema,
			cinemaId,
			getContactsRequest,
			getAboutCinemaRequest,
			getAdvertisements,
			resetAdvertisements,
			getPromos,
			resetPromos,
			isSeveralCinemasInCity,
			updateCurrentCinema,
			getCustomPagesRequest,
			resetCurrentCinemaInfo,
			resetPages,
			cityId,
			getNews,
			resetNews,
			offsetNews,
			branding,
			currentCinemas,
			isKinokassa,
			getCinemaSettings,
			getQualifiers
		} = this.props;

		const today = moment().format('YYYY-MM-DD');

		if (prevProps.branding !== branding && !_.isEmpty(branding) && branding.enabled) {
			document.body.style.backgroundColor = branding.bg_color;
			this.background.style.backgroundImage = `url(${branding.img_url})`;
		}

		if (alert && prevProps.alert !== alert && !cinema.isKinokassa) {
			const popupContentNode = this.getPopupContentNode(alert);

			this.props.showPopup({
				popupContentNode,
				withCloseButton: false
			});
		}

		if (!__DEV__ && !isKinokassa &&
			((!prevProps.currentCinemas.length && currentCinemas.length) || cityId !== prevProps.cityId)) {
			const currentCinemasIds = currentCinemas.map((cinemaObj) => cinemaObj.id);

			getAnalyticsRequest(currentCinemasIds);
		}

		if (isSeveralCinemasInCity) {
			if (cityId !== prevProps.cityId) {
				resetCurrentCinemaInfo();
				resetAdvertisements();
				resetPages();
				getPromos(null, cityId);
				updateCurrentCinema(null, cityId);
				getCustomPagesRequest(null, cityId);
				getNews(cinemaId, cityId, offsetNews);
				getQualifiers(today, cityId);

				if (!isKinokassa) {
					getAdvertisements(null, cityId);
				}
			}
		} else {
			if (cinemaId && cinemaId !== prevProps.cinemaId) {
				resetPages();
				resetPromos();
				resetNews();
				updateCurrentCinema(cinemaId, cityId);
				getCustomPagesRequest(cinemaId);
				getContactsRequest(cinemaId);
				getAboutCinemaRequest(cinemaId);
				getCinemaSettings(cinemaId, cityId);
				getQualifiers(today, cityId);

				if (!isKinokassa) {
					getAdvertisements(cinemaId);
				}
			}

			if (cinema.modules && (cinema.id !== prevProps.cinema.id || cinemaId !== prevProps.cinemaId)) {
				if (cinema.modules.promotions_enabled) {
					getPromos(cinemaId);
				}
				if (cinema.modules.articles_enabled) {
					getNews(cinemaId, cityId, offsetNews);
				}
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.setAuthStatus);
		window.removeEventListener('message', this.setWidgetKioskModeStatus);
		window.removeEventListener('message', this.setCountUnreadNotifications);
		window.removeEventListener('resize', this.checkScreenSize);
		window.removeEventListener('message', this.handleMessage);
	}

	setAuthStatus = ({data: {isUserLoggedIn}}) => {
		// TODO: событие прослушивается всегда и перезаписывается при любом действии,
		// стоит перенести его только на логин/логаут в виджете
		if (isUserLoggedIn !== undefined && isUserLoggedIn !== null) {
			localStorage.setItem('isUserAuth', isUserLoggedIn);
			this.props.setIsUserAuth(JSON.parse(isUserLoggedIn));
		}
	}

	setWidgetKioskModeStatus = ({data: {isKioskMode}}) => {
		if (isKioskMode !== undefined) {
			localStorage.setItem('isWidgetInKioskMode', isKioskMode);
			this.props.setIsWidgetInKioskMode(isKioskMode);
		}
	}

	setCountUnreadNotifications = ({data: {countUnreadNotifications}}) => {
		if (countUnreadNotifications) {
			localStorage.setItem('countUnreadNotifications', countUnreadNotifications);
			this.props.setCountUnreadNotifications(JSON.parse(countUnreadNotifications));
		}
	}

	getPopupContentNode = ({title, subtitle}) => (
		<div className="popup__content">
			<div className="popup__content__header">
				{title}
			</div>
			<div className="popup__notification">
				{parse(subtitle)}
			</div>
			<div className="popup__content__bottom" style={{justifyContent: 'center'}}>
				<KeydownEnterButton
					// eslint-disable-next-line jsx-a11y/tabindex-no-positive
					tabIndex={1}
					className="button button--accent"
					onClick={this.props.handleHidePopup}
				>
					{i18n.t('Close')}
				</KeydownEnterButton>
			</div>
		</div>
	);

	checkScreenSize = _.debounce(() => {
		const {setIsMobile, isMobile} = this.props;
		const sizes = window.outerWidth;

		if (!isMobile && sizes <= MOBILE_MAX_SIZE) setIsMobile(true);
		else if (isMobile && sizes > MOBILE_MAX_SIZE) setIsMobile(false);

		this.setState({windowWidth: window.innerWidth});
	}, 10);

	handleMessage = ({data: {reachGoal, params}}) => {
		if (reachGoal) {
			setYaReachGoal(reachGoal, params || {});
		}
	};

	render() {
		const {
			cinema,
			isSmartBannerShown,
			menuLinks,
			isSemiblind,
			branding,
			isMonitor,
			withNewDesign,
			hasAdvertisementsList
		} = this.props;

		const brandingEnabled = !_.isEmpty(branding) && branding.enabled;

		return (
			<BrowserRouter>
				<div
					className={classNames('app', {
						'app--with-branding': brandingEnabled && !isSemiblind
					})}
				>
					{brandingEnabled && !isRefund &&
						<a
							className={classNames('branding g-mobile-hide', {
								'branding--hide': isSemiblind
							})}
							ref={(background) => { this.background = background; }}
							href={branding.link}
							target={branding.new_tab ? '_blank' : null}
							rel="noreferrer"
						/>
					}
					{isSemiblind && <ViewContainer />}
					{isRefund
						? (
							<Switch>
								<Route component={Refund} />
							</Switch>
						) : (
							<div className="wrapper">
								{!isPreview && isSmartBannerShown && (!isFondKino || isMonitor) && <SmartBannerContainer />}
								{withNewDesign
									? this.state.windowWidth > TABLET_MAX_SIZE ? <Header.Kinokassa /> : <Header.Mobile />
									: isFondKino || cinema.id === VREMYAKINO_CINEMA_ID ? <FondKinoHeader /> : <Header.Main />
								}
								{!withNewDesign && hasAdvertisementsList && !isSemiblind ? <Advertisements /> : null}
								{!withNewDesign ? <Menu.Navigation links={menuLinks} /> : null}
								<AppRoutes {...this.props} />
								{withNewDesign ? <FooterContainer.Kinokassa /> : <FooterContainer.Main />}
							</div>
						)
					}
					<PopupContainer />
				</div>
			</BrowserRouter>
		);
	}
}
