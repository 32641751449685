import { memo, useEffect } from 'react';
import * as style from 'css/release-item-details.module.scss';
import Flex from 'app/ui/Flex/Flex';
import parse from 'html-react-parser';
import ReleasePoster from 'app/components/release_item/ReleasePoster';
import { IRelease } from 'app/types/releases';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import {closeIcon} from 'app/constants/IconsConstants';
import ReleaseCharacteristics from 'app/components/release_item/ReleaseCharacteristics';
import ReleaseTrailer from 'app/components/release_item/ReleaseTrailer';
import { ID_ELEMENT_RELEASE_SCHEDULE } from 'app/components/release_page/constants';
import withScroll from 'app/hocs/withScroll';
import { useMobileViewBreakPoint } from 'app/hooks';
import { TABLET_MAX_SIZE } from 'app/constants/AppConstants';

const BuyTicketsBtn = withScroll(KeydownEnterButton);

interface IProps {
	release: IRelease;
	onClose: (value: boolean) => void;
}

function ReleaseDetails({release, onClose}: IProps) {
	const {isMobileView} = useMobileViewBreakPoint(TABLET_MAX_SIZE);
	const {id, poster, title, trailer, screenshots, description, description_html} = release;

	useEffect(() => {
			document.body.classList.add('scroll-hidden');

		return () => {
			document.body.classList.remove('scroll-hidden');
		}
	}, []);

	const thumbnails = _.compact([
		trailer && Object.values(trailer).some(prop => prop !== null) && {
			thumbnail: trailer?.thumbnail,
			original: trailer?.preview || trailer?.hd,
		},
		...screenshots.map((item) => ({
			thumbnail: item,
			original: '',
		}))
	]);

	return (
		<Flex classes={style.releaseDetails}>
			<KeydownEnterButton className={style.releaseBtnClose} onClick={() => onClose(false)}>
				{closeIcon}
			</KeydownEnterButton>

			{title && isMobileView ? <h2 className={style.releaseTitle}>{title}</h2> : null}

			<Flex classes={style.releaseDetailsWraper}>
				<Flex classes={style.releasePosterWraper} direction="column">
					<ReleasePoster
						poster={poster}
						isShowProculture={false}
						isReleasePage={true}
					/>
					<BuyTicketsBtn
						className={`button button--accent ${style.releaseButton}`}
						onClick={() => onClose(false)}
						targetElementId={ID_ELEMENT_RELEASE_SCHEDULE}
					>
						{i18n.t('BuyTickets')}
					</BuyTicketsBtn>
					<ReleaseCharacteristics release={release} />
				</Flex>

				<Flex element="section" classes={style.releaseDesc} direction="column">
					{title && !isMobileView ? <h2 className={style.releaseTitle}>{title}</h2> : null}

					{thumbnails.length
						? (
							<ReleaseTrailer
								releaseID={id}
								releaseTitle={title}
								items={thumbnails}
							/>
						) : null}
					<div className="text text--zize-16">
						<span className={`text text--zize-12 text--gray ${style.releaseDescLabel}`}>{i18n.t('Description')}</span>
						{description_html ? parse(description_html) : description}
					</div>
				</Flex>
			</Flex>
		</Flex>
	);
}

export default memo(ReleaseDetails);
