import { useSelector } from 'react-redux';
import {
	getRoutePrefix,
} from 'app/selectors/AppSelectors';
import { getAllReleasesSoon } from 'app/selectors/ReleasesSoonSelectors';
import { getPromosWithCinemas } from 'app/selectors/PromoSelectors';
import { getNewsSelector } from 'app/selectors/NewsSelectors';
import { getSectionName } from 'app/constants/SettingsConstants';
import { arrowIcon } from 'app/constants/IconsConstants';
import { lazy } from 'react';
import { loadComponent } from 'app/selectors/Helpers';
import PromoOthersComponent from 'app/components/PromoOthersComponent';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import NewsList from 'app/components/NewsListComponent';

const ReleasesSoon = loadComponent(lazy(() => import('app/containers/ReleasesSoonContainer')));

const AfishaAdditionalSections = ({
	page,
}: {
	page: string;
}) => {
	const promos = useSelector(getPromosWithCinemas);
	const prefix = useSelector(getRoutePrefix);
	const news = useSelector(getNewsSelector);
	const hasReleasesSoon= useSelector(getAllReleasesSoon).length;

	return (
		<>
			{!!promos.length ? <PromoOthersComponent promos={promos} prefix={prefix} page={page} /> : null}
			{!!news.length ? (
					<div className="container news-container">
						<div className="news-list-wrapper">
							<div className="news-list__header">
								<div className="news__title">{getSectionName()}</div>
								<div className="promo__title-container">
									<KeydownEnterLink
										to={`${prefix}/news`}
										className="promo__title-link promo__title-link--black"
									>
										<span>{i18n.t('ShowAll')} </span>
									</KeydownEnterLink>
									{arrowIcon}
								</div>
							</div>
							<NewsList news={_.first(news, 3)} prefix={prefix} page={page}/>
						</div>
					</div>
				) : null
			}
			{hasReleasesSoon ? <ReleasesSoon page={page} /> : null}
		</>
	)
}

export default AfishaAdditionalSections;