import React, {memo, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import { closeIcon } from "app/constants/IconsConstants";
import { IRelease } from 'app/types/releases';
import ReleaseQualifiers from 'app/components/ReleaseQualifiers';
import { formatMoment } from "app/selectors/Helpers";
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCurrentCinemas,
	getCurrentCityId,
	getIsKinokassa,
	getRoutePrefix,
	getSelectedDay
} from 'app/selectors/AppSelectors';
import { getAfishaSelector, getHasAfishaData } from 'app/selectors/AfishaSelectors';
import { hidePopup } from 'app/actions/AppActions';
import { getAfisha } from 'app/actions/AfishaActions';

const SearchRelease = () => {
	const dispatch = useDispatch();

	const hasAfishaData = useSelector(getHasAfishaData);
	const afisha = useSelector(getAfishaSelector);
	const prefix = useSelector(getRoutePrefix);
	const selectedDay = useSelector(getSelectedDay);
	const cinemasCount = useSelector(getCurrentCinemas).length;
	const isKinokassa = useSelector(getIsKinokassa);
	const cityID = useSelector(getCurrentCityId);

	const [isFocused, setIsFocused] = useState(false);
	const [filteredAfisha, setFilteredAfisha] = useState<IRelease[]>([]);

	const gradientClass = `gradient_${_.random(1, 8)}`;
	const inputClassName = classNames(
		'search-release-popup__input',
		{'search-release-popup__input--focused': isFocused},
	);

	useEffect(() => {
		if (cityID && cinemasCount && !hasAfishaData) {
			dispatch(
				getAfisha(
					formatMoment(selectedDay),
					cityID,
					{ isKinokassa, hasSeveralCinemas: cinemasCount > 1 }
				)
			);
		}
	}, [cityID, isKinokassa, cinemasCount, selectedDay, hasAfishaData]);

	useEffect(() => {
		setFilteredAfisha([]);
	}, []);

	const toggleFocus = () => {
		setIsFocused(!isFocused);
	};

	const handleChange = ({target}) => {
		if (afisha && target.value.length > 2) {
			const filter = afisha.filter(release => {
				const title = release.title.toLowerCase().replace(/[.,\-!?:]/g, '');

				return title.includes(target.value.toLowerCase())
			});

			setFilteredAfisha(filter);
		} else {
			setFilteredAfisha([]);
		}
	};

	const handleCloseSearch = useCallback(() => {
		dispatch(hidePopup());
	}, []);

	return (
		<div className="search-release-popup">
			<form>
				<input
					className={inputClassName}
					placeholder={i18n.t('ReleaseName')}
					onFocus={toggleFocus}
					onBlur={toggleFocus}
					onChange={handleChange}
					autoFocus
				/>
				<div className="search-release-popup__icon" onClick={handleCloseSearch}>
					{closeIcon}
				</div>
			</form>
			<div className="search-results">
				{filteredAfisha.map(release => (
					<section className="search-results__item" key={release.id}>
						<div className={`search-results__poster ${gradientClass}`}>
							{!!release.thumbnail &&
								<img
									data-src={release.thumbnail}
									className="lazyload search-results__poster-img"
									alt={`${i18n.t('ReleasePoster')} ${release.title}`}
								/>
							}
						</div>
						<KeydownEnterLink
							to={`${prefix}/release/${release.id}${
								selectedDay ? `?date=${formatMoment(selectedDay)}` : ''
							}`}
							className="search-results__desc text--unlink"
							onClick={handleCloseSearch}
						>
							{!!release.all_qualifiers.length && !release.all_qualifiers.every(item => item === 'custom')
								? (
									<div className="search-results__qualifiers">
										<ReleaseQualifiers qualifiers={release.all_qualifiers} />
									</div>
								) : null}
							<h2 className="search-results__item-title text text--size-32">{release.title}</h2>
						</KeydownEnterLink>
					</section>
				))}
			</div>
		</div>
	);
};

export default memo(SearchRelease);
