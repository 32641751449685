import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import AfishaComponent from 'app/components/afisha/AfishaComponent';

type Props = RouteComponentProps & {
	addedPath: string,
};

const NotMovieAfishaPageComponent = ({addedPath}: Props) => {
	return (
		<Switch>
			<Route path={`${addedPath}/not_movies`}>
				<AfishaComponent isNotMovie />
			</Route>
		</Switch>
	);
};

export default withRouter(NotMovieAfishaPageComponent);
