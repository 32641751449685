import PropTypes from 'prop-types';
import {
	getSectionName,
	getAboutName,
} from 'app/constants/SettingsConstants';
import {imaxIcon, vipIcon} from 'app/constants/IconsConstants';

export const getAppMenuLinks = () => {
	const customMenuLinks = (_.propertyOf(window.kinosite)('menuLinks') || (() => []))();

	const mappedCustomMenuLinks = customMenuLinks.map((linkObject) => (
		{
			...linkObject,
			isCustomCinemaLink: true
		}
	));

	return [
		{
			href: '/',
			title: i18n.t('Afisha')
		},
		{
			href: '/seances',
			title: i18n.t('Seances')
		},
		...mappedCustomMenuLinks
	];
};

export const getAboutCinemaLink = () => ({
	href: '/cinema',
	title: getAboutName()
});

export const getNewsMenuLink = () => ({
	href: '/news',
	title: getSectionName()
});

export const getPromoLinkMenu = () => ({
	href: '/promo',
	title: i18n.t('PromosAndDiscounts')
});

export const getSoonAtMenu = () => ({
	href: '/soon',
	title: i18n.t('SoonAtCinemas')
});

export const getCityCinemasLink = () => ({
	href: '/cinemas',
	title: i18n.t('Cinemas')
});

export const getCinemaContactsLink = () => ({
	href: '/contacts',
	title: i18n.t('Contacts')
});

export const getGosuslugiPageLink = () => ({
	href: '/gosuslugi',
	title: i18n.t('Gosuslugi')
});

export const getProCultureAfishaLink = () => ({
	href: '/pushkin_card',
	title: i18n.t('PushkinCard')
});

export const getNotMovieAfishaLink = () => ({
	href: '/not_movies',
	title: i18n.t('NotAMovie')
});

export const getVipLinkMonitor = () => ({
	href: '/vip',
	title: vipIcon,
	isCustomCinemaLink: true,
	sub: [
		{
			href: '/vip/afisha',
			title: i18n.t('Afisha')
		},
		{
			href: '/vip/news',
			title: i18n.t('Events')
		}
	]
});

export const getImaxLinkMonitor = () => ({
	href: '/imax',
	title: imaxIcon,
	isCustomCinemaLink: true,
	sub: [
		{
			href: '/imax/afisha',
			title: i18n.t('Afisha')
		},
		{
			href: '/imax/news',
			title: i18n.t('Events')
		}
	]
});

export const getVipLink = () => ({
	href: '/vip/afisha',
	title: vipIcon
});

export const getImaxLink = () => ({
	href: '/imax/afisha',
	title: imaxIcon
});

export const AdvertisementsPropType = PropTypes.arrayOf(
	PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
		original: PropTypes.string,
		bulletClass: PropTypes.string,
		url: PropTypes.string,
		originalClass: PropTypes.string,
		advertiser: PropTypes.string
	}),
);

export const CinemaPropType = PropTypes.shape({
	address: PropTypes.string,
	app: PropTypes.object,
	modules: {
		articles_enabled: PropTypes.bool,
		branding_enabled: PropTypes.bool
	},
	city: PropTypes.string,
	details: PropTypes.bool,
	facebook: PropTypes.string,
	id: PropTypes.number,
	instagram: PropTypes.string,
	maps: PropTypes.string,
	odnoklassniki: PropTypes.string,
	payment: PropTypes.object,
	phoneNumbers: PropTypes.arrayOf(PropTypes.string),
	place: PropTypes.string,
	saleRules: PropTypes.bool,
	selectButton: PropTypes.object,
	soonPageIsEnabled: PropTypes.bool,
	title: PropTypes.string,
	twitter: PropTypes.string,
	vk: PropTypes.string,
	workTime: PropTypes.object,
	youtube: PropTypes.string,
	coordinates: PropTypes.string
});

export const AddressesPropTypes = PropTypes.shape({
	main: PropTypes.string,
	mapsLink: PropTypes.string,
	place: PropTypes.string
});

export const AppsPropTypes = PropTypes.shape({
	apple: PropTypes.string,
	google: PropTypes.string
});

export const SocialsPropTypes = PropTypes.shape({
	facebook: PropTypes.string,
	instagram: PropTypes.string,
	ok: PropTypes.string,
	twitter: PropTypes.string,
	vk: PropTypes.string,
	youtube: PropTypes.string
});

export const PhonesPropTypes = PropTypes.arrayOf(PropTypes.shape({
	name: PropTypes.string,
	value: PropTypes.string,
	comment: PropTypes.string
}));

export const ContactsPropType = PropTypes.shape({
	addresses: AddressesPropTypes,
	apps: AppsPropTypes,
	phones: PhonesPropTypes,
	socials: SocialsPropTypes
});

export const ReleasePropType = PropTypes.shape({
	id: PropTypes.number,
	rambler_id: PropTypes.number,
	title: PropTypes.string,
	age_rating: PropTypes.string,
	formats: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	genres: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	countries: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	cast: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})),
	directors: PropTypes.arrayOf(PropTypes.string),
	trailer: PropTypes.shape({
		preview: PropTypes.string,
		thumbnail: PropTypes.string,
		hd: PropTypes.string
	}),
	rating: PropTypes.shape({
		kinopoisk: PropTypes.number,
		imdb: PropTypes.number
	}),
	description: PropTypes.string,
	year: PropTypes.number,
	poster: PropTypes.string,
	thumbnail: PropTypes.string,
	duration: PropTypes.number,
	qualifiers: PropTypes.arrayOf(PropTypes.string),
	russia_start_date: PropTypes.string,
	cinema_start_date: PropTypes.string
});

export const currentYear = new Date().getFullYear();

export const MONITOR_SUPPORT_EMAIL = 'sp@kinomonitor.ru';

export const SUPPORT_EMAIL = 'tickets@kinoplan.ru';
export const SUPPORT_PHONE = '8 495 980-17-27';

export const COUNT_NEWS_PAGE = 10;
export const COUNT_OTHER_NEWS_PAGE = 4;

export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const MOBILE_MAX_SIZE = 767;
export const TABLET_MAX_SIZE = 991;

export const MOBILE_POSTERS_AMOUNT = 9;
export const DESKTOP_POSTERS_AMOUNT = 12;

export const CUSTOM_MARKER_OFFSET = [-24, -60];
export const DEFAULT_MARKER_OFFSET = [-16, -16];
export const DEFAULT_MAP_ZOOM = 16;

export const MENU_HEIGHT = 48;

export const ENTER_KEY_CODE = 13;

export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export const GET_APP_DATA_REQUEST = 'GET_APP_DATA_REQUEST';
export const GET_APP_DATA_SUCCESS = 'GET_APP_DATA_SUCCESS';
export const SET_CINEMA_SETTINGS = 'SET_CINEMA_SETTINGS';
export const SET_APP_ERROR = 'SET_APP_ERROR';

export const SET_ADVERTISEMENTS = 'SET_ADVERTISEMENTS';
export const RESET_ADVERTISEMENTS = 'RESET_ADVERTISEMENTS';

export const SET_DAILY_SEANCES = 'SET_DAILY_SEANCES';
export const SET_DAILY_SEANCES_IS_FETCHING = 'SET_DAILY_SEANCES_IS_FETCHING';
export const GET_DAILY_SEANCES_FAIL = 'GET_DAILY_SEANCES_FAIL';
export const SET_DAILY_SEANCES_ERROR = 'SET_DAILY_SEANCES_ERROR';

export const GET_AFISHA_REQUEST = 'GET_AFISHA_REQUEST';
export const GET_AFISHA_SUCCESS = 'GET_AFISHA_SUCCESS';
export const GET_AFISHA_INFO_REQUEST = 'GET_AFISHA_INFO_REQUEST';
export const GET_AFISHA_INFO_SUCCESS = 'GET_AFISHA_INFO_SUCCESS';
export const SET_AFISHA_ERROR = 'SET_AFISHA_ERROR';
export const SET_SEANCE_DISPLAY_SETTINGS = 'SET_SEANCE_DISPLAY_SETTINGS';
export const SET_QUALIFIERS = 'SET_QUALIFIERS';

export const SET_CONTACTS = 'SET_CONTACTS';
export const RESET_CURRENT_CINEMA_INFO = 'RESET_CURRENT_CINEMA_INFO';
export const SET_ABOUT_CINEMA = 'SET_ABOUT_CINEMA';
export const SET_SEVERAL_CINEMAS_ABOUT_DATA = 'SET_SEVERAL_CINEMAS_ABOUT_DATA';

export const SET_HALLS = 'SET_HALLS';

export const GET_RELEASES_SOON_REQUEST = 'GET_RELEASES_SOON_REQUEST';
export const GET_RELEASES_SOON_SUCCESS = 'GET_RELEASES_SOON_SUCCESS';
export const GET_RELEASES_SOON_FAIL = 'GET_RELEASES_SOON_FAIL';

export const GET_RELEASE_REQUEST = 'GET_RELEASE_REQUEST';
export const GET_RELEASE_SUCCESS = 'GET_RELEASE_SUCCESS';
export const SET_RELEASE_ERROR = 'SET_RELEASE_ERROR';
export const RESET_RELEASE_ITEM_STATE = 'RESET_RELEASE_ITEM_STATE';

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';
export const RESET_NEWS = 'RESET_NEWS';

export const GET_NEWS_ITEM_REQUEST = 'GET_NEWS_ITEM_REQUEST';
export const GET_NEWS_ITEM_SUCCESS = 'GET_NEWS_ITEM_SUCCESS';
export const GET_NEWS_ITEM_FAIL = 'GET_NEWS_ITEM_FAIL';

export const SET_SELECTED_DAY = 'SET_SELECTED_DAY';
export const CLOSE_SMART_BANNER = 'CLOSE_SMART_BANNER';
export const SET_SEMIBLIND = 'SET_SEMIBLIND';
export const SET_FONT_SIZE = 'SET_FONT_SIZE';

export const SET_NEW_CURRENT_CINEMA_DATA = 'SET_NEW_CURRENT_CINEMA_DATA';
export const SET_CITY_FILTER = 'SET_CITY_FILTER';
export const SET_CURRENT_CITY_ID = 'SET_CURRENT_CITY_ID';
export const SET_IS_USER_AUTH = 'SET_IS_USER_AUTH';
export const SET_IS_WIDGET_IN_KIOSK_MODE = 'SET_IS_WIDGET_IN_KIOSK_MODE';
export const SET_COUNT_UNREAD_NOTIFICATIONS = 'SET_COUNT_UNREAD_NOTIFICATIONS';

export const INTERNAL_SERVICE_ERROR = 201;

export const LIMIT_ORDER_NUMBER = 8;
export const REFUND_LINK = 'https://kinokassa.ru';

export const __DEV__ = process.env.NODE_ENV !== 'production';

export const BRIGHTNESS_MIDDLE_VALUE = 125;

export const isEnabledSalesRules = !!window.kinosite?.additionalModules?.saleRulesPage ?? false;
export const isEnabledDetails = !!window.kinosite?.additionalModules?.detailsPage ?? false;
export const gosuslugiOnAfishaData = window.kinosite?.additionalModules?.gosuslugiOnAfisha;