import * as style from 'css/release-poster.module.scss';
import { memo } from 'react';
import cn from 'classnames';
import ProcultureLabel from 'app/components/release_item/ProcultureLabel';
import ReleaseQualifiers from 'app/components/ReleaseQualifiers';
import Flex from 'app/ui/Flex/Flex';
import { getContrastColorForAcccent } from 'app/selectors/Helpers';
import { Qualifiers } from 'app/types/releases';
import { useSelector } from 'react-redux';
import { getWithNewDesign } from 'app/selectors/AppSelectors';

interface IProps {
	poster: string;
	isSoon?: boolean;
	isBadgeShow?: boolean;
	isShowProculture?: boolean;
	isShowOnSale?: boolean;
	isShowAgeRating?: boolean;
	ageRating?: string;
	qualifiers?: Qualifiers[];
	isAfisha?: boolean;
	isReleasePage?: boolean;
}

const defaultValues = {
	isSoon: false,
	isBadgeShow: false,
	isShowOnSale: false,
	isShowAgeRating: false,
	ageRating: '',
	isAfisha: false,
	isShowProculture: false,
	qualifiers: [],
	isReleasePage: false
}

function ReleasePoster({
	poster,
	isSoon = defaultValues.isSoon,
	isBadgeShow = defaultValues.isBadgeShow,
	isShowProculture,
	isShowOnSale = defaultValues.isShowOnSale,
	isShowAgeRating = defaultValues.isShowAgeRating,
	ageRating = defaultValues.ageRating,
	qualifiers,
	isAfisha,
	isReleasePage
}: IProps) {
	const withNewDesign = useSelector(getWithNewDesign);
	const gradientClass = `gradient_${_.random(1, 8)}`;
	const onSaleLabelTextColor = withNewDesign ? getContrastColorForAcccent() : '';
	const posterClass = cn([style.releasePoster, gradientClass], {
		[style.releaseItemKinokassa]: withNewDesign && isReleasePage,
		[style.releasePosterSoon]: isSoon,
		[style.releasePosterOld]: !withNewDesign && !isSoon,
	});

	return (
		<Flex classes={posterClass} justifyContent="center" alignItems="center">
			{isAfisha ? <div className="releases-item__poster-height" /> : null}
			<div className={`${style.posterWrapper}`}>
				{poster && <img 
					src={poster}
					data-src={poster}
					data-name="poster" 
					className={cn('lazyload', [style.posterImg], {
						[style.afishaPosterImg]: isAfisha,
						[style.releaseItemPosterImg]: !isAfisha
					})}
					alt={i18n.t('ReleasePoster')}
				/>}
			</div>
			{isShowAgeRating ? <div className="release__age text text--size-15">{ageRating}</div> : null}
			{isBadgeShow
				? (
					// TODO: вынести в отдельный компонент
					<div className="releases-item__badge text text--size-12 releases-item__badge--desktop">
						{qualifiers?.length ? <ReleaseQualifiers qualifiers={qualifiers} /> : null}
						{isShowProculture ? <ProcultureLabel /> : null}
					</div>
				) : null}
			{isShowOnSale
				? (
					<div className={`${style.posterOnSaleLabel}`} style={{color: onSaleLabelTextColor}}>
						{i18n.t('TicketsAtSold').toUpperCase()}
					</div>
				) : null}
		</Flex>
	)
}

export default memo(ReleasePoster);