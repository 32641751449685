import React, {Fragment, lazy} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import { MdKeyboardArrowDown } from '@react-icons/all-files/md/MdKeyboardArrowDown';
import SocialLinksComponent from 'app/components/SocialLinksComponent';
import {setYaReachGoal, loadComponent} from 'app/selectors/Helpers';
import {
	AddressesPropTypes,
	CinemaPropType,
	PhonesPropTypes,
	SocialsPropTypes,
} from 'app/constants/AppConstants';
import CitySelect from 'app/containers/CitySelect/CitySelectContainer';
import FeedbackContainer from 'app/containers/FeedbackContainer';
import {KINOKHV_CINEMA_IDS} from 'app/constants/CinemaConstants';
import LoginButton from 'app/containers/LoginButtonContainer';
import RefundButton from 'app/components/refund/RefundButton';

const PhonesComponent = loadComponent(lazy(() => import('app/components/PhonesComponent')));
const Select = loadComponent(lazy(() => import('tochkak-ui/lib/Select')));

export default class HeaderComponent extends React.Component {
	static propTypes = {
		cinema: CinemaPropType.isRequired,
		hasSelectButton: PropTypes.bool.isRequired,
		isMobile: PropTypes.bool.isRequired,
		addresses: AddressesPropTypes.isRequired,
		phones: PhonesPropTypes.isRequired,
		socials: SocialsPropTypes.isRequired,
		langOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
		isSemiblind: PropTypes.bool.isRequired,
		setSemiblind: PropTypes.func.isRequired,
		setFontSize: PropTypes.func.isRequired,
		isSeveralCinemasInCity: PropTypes.bool.isRequired,
		isCitySelectShown: PropTypes.bool.isRequired,
		menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
		isFeedbackEnabled: PropTypes.bool.isRequired
	}

	static defaultProps = {
		langOptions: []
	}

	state = {
		openMenu: false,
		isOpenedPhoneMenu: false,
		currentLang: i18n.language
	}

	handleLangChange = (currentLang) => {
		this.setState({
			currentLang
		});

		i18n.changeLanguage(currentLang);
		localStorage.setItem('lang', currentLang);
		window.location.reload();
	}

	handleClickMenu = (event) => {
		if (event.currentTarget.dataset.sub) {
			event.preventDefault();
			event.stopPropagation();

			const isSubMenu = event.currentTarget.classList.contains('header-menu-sub__link');
			const element = isSubMenu ? event.target.closest('.header-menu__link') : event.currentTarget;

			element.classList.toggle('header-menu__link--active');
		} else {
			const {isCustomCinemaLink, linkTitle} = event.target.dataset;

			if (isCustomCinemaLink) {
				setYaReachGoal('open-section', {section: linkTitle});
			}

			this.setState({
				openMenu: !this.state.openMenu
			});
		}
	}

	handleClickOption = (event, selectedValue) => {
		setYaReachGoal('pick-cinema', null, false);
		window.location.replace(selectedValue);
	}

	handleClickPhone = () => {
		this.setState(prevState => ({isOpenedPhoneMenu: !prevState.isOpenedPhoneMenu}));
	}

	handleIconClick = (event) => {
		const {icon} = event.currentTarget.dataset;

		setYaReachGoal(`${icon}-click`);
	}

	handleSemiblindClick = () => {
		const {setSemiblind, setFontSize} = this.props;

		localStorage.setItem('colorTheme', 'blackOnWhite');
		localStorage.setItem('showImages', 'imageOn');
		localStorage.setItem('fontSize', '16');
		localStorage.setItem('fontType', 'noSerif');
		localStorage.setItem('lineHeight', 'line_1_5');
		localStorage.setItem('letterSpacing', 'letter-spacing1');

		setSemiblind(true);
		setFontSize(16);
	};

	address = () => {
		const {cinema: {address, city}, addresses: {place, maps}} = this.props;

		return (
			<div className="header__address">
				<KeydownEnterLink
					type="plain"
					className="header__link"
					href={maps}
					target="_blank"
					rel="noopener noreferrer"
					data-icon="maps"
					onClick={this.handleIconClick}
				>
					<div className="header__text">
						<div>
							{city}
						</div>
						<div className="header__text-addition">
							{!!place && `${place},`}
						</div>
						<div className="header__text-addition">
							{address}
						</div>
					</div>
				</KeydownEnterLink>
			</div>
		);
	}

	renderSelect = () => {
		const {selectButton, id: cinemaID} = this.props.cinema;

		return (
			<div className="header__select-button">
				{KINOKHV_CINEMA_IDS.includes(cinemaID) &&
					<span>
						{i18n.t('ChooseCinema')}:
					</span>
				}
				<Select
					options={selectButton.options}
					onSelect={this.handleClickOption}
					selectedOptionValue={selectButton.selectedOptionValue}
				/>
			</div>
		);
	}

	render() {
		const {openMenu, currentLang} = this.state;
		const {
			isMobile,
			hasSelectButton,
			cinema,
			langOptions,
			phones,
			socials,
			isSemiblind,
			isSeveralCinemasInCity,
			isCitySelectShown,
			isFeedbackEnabled,
			menuLinks
		} = this.props;

		const menuIconClass = classNames('header-menu__icon', {
			'header-menu__icon--active': openMenu
		});
		const menuNavigationClass = classNames('header-menu__navigation', {
			'header-menu__navigation--open': openMenu
		});

		return (
			<header className="header" id="header">
				<div className="container header__container">
					<span className="header-group header-group--left">
						<KeydownEnterLink
							type="plain"
							href="/"
							className="header__logo"
							rel="noopener noreferrer"
						>
							<img className="header__logo-img" src="/img/logo.svg" alt={i18n.t('Logo')} />
						</KeydownEnterLink>
						{!isSeveralCinemasInCity && (
							<>
								{!isMobile && hasSelectButton && this.renderSelect()}
								{this.address()}
								<PhonesComponent
									oldPhones={cinema.phoneNumbers}
									newPhones={phones}
									onClick={this.handleIconClick}
								/>
							</>
						)}
					</span>
					<span className="header-group header-group--right">
						<SocialLinksComponent
							className="header__social"
							linksData={socials}
						/>
						{!isMobile && (
							<RefundButton
								classNamesOfContainers={["feedback__item__container"]}
								linkClassName="feedback__item return_ticket__button"
							/>
						)}
						{isMobile ? <LoginButton /> : null}
						<div className="header-menu" onClick={this.handleClickMenu}>
							<div className={menuIconClass} />
						</div>
						{isCitySelectShown && <CitySelect.Default isDesktopView={!isMobile} />}
					</span>
				</div>
				{isMobile && hasSelectButton &&
					<div className="container header__container--mobile">
						{this.renderSelect()}
					</div>
				}

				{/* меню для мобилок от 767px */}
				<div id="headerMenu" className={menuNavigationClass}>
					{!isSeveralCinemasInCity && (
						<>
							{this.address()}
							<PhonesComponent
								oldPhones={cinema.phoneNumbers}
								newPhones={phones}
								onClick={this.handleIconClick}
								isMobileMenu
							/>
							{langOptions.length ? <div className="header__select-button">
								<Select
									options={langOptions}
									onSelect={this.handleLangChange}
									selectedOptionValue={currentLang}
									className="header__change_lang-button"
									buttonText="Выберите язык"
								/>
							</div> : null}
						</>
					)}
					<div
						className="header-menu__links"
						ref={(links) => { this.links = links; }}
					>
						{menuLinks.map((link, index) =>
							<KeydownEnterLink
								type="navLink"
								key={index}
								to={link.href}
								exact
								data-sub={link.sub && link.sub.length !== 0 ? link.sub.length : null}
								data-is-custom-cinema-link={link.isCustomCinemaLink}
								data-link-title={link.sub && link.sub.length ? link.sub[0].title : link.title}
								activeClassName="header-menu__link--active"
								className="header-menu__link"
								onClick={this.handleClickMenu}
							>
								{link.sub && link.sub.length ?
									<span className="header-menu__arrow">
										<MdKeyboardArrowDown size={24} />
									</span>
									: null}
								{link.title}

								{link.sub && link.sub.length ?
									<div
										className="header-menu-sub"
										ref={(subLinks) => { this.subLinks = subLinks; }}
									>
										{link.sub.map((subLink, subIndex) =>
											<KeydownEnterLink
												type="navLink"
												key={subIndex}
												to={subLink.href}
												exact
												data-is-custom-cinema-link={link.isCustomCinemaLink}
												data-link-title={subLink.title}
												activeClassName="header-menu-sub__link--active"
												className="header-menu-sub__link"
												onClick={this.handleClickMenu}
											>
												{subLink.title}
											</KeydownEnterLink>,
										)}
									</div>
									: null}
							</KeydownEnterLink>,
						)}
						{isFeedbackEnabled && <FeedbackContainer />}
						<RefundButton
							classNamesOfContainers={["feedback__item__container", "feedback"]}
							linkClassName="feedback__item return_ticket__button"
						/>
					</div>
					<SocialLinksComponent
						className="header__social"
						linksData={socials}
					/>
					{!isSemiblind && (
						<div className="header__view">
							<KeydownEnterButton
								className="footer__view-button view-button"
								onClick={this.handleSemiblindClick}
							>
								{i18n.t('VersionForTheVisuallyImpaired')}
							</KeydownEnterButton>
						</div>
					)}
				</div>
			</header>
		);
	}
}
