import { isProCultureRelease } from 'app/selectors/Helpers';
import { IRelease, Qualifiers } from 'app/types/releases';
import { IReduxState } from 'app/types/state';
import { createSelector } from 'reselect';

export const getReleaseSelector = (state: IReduxState) => state.releaseReducer.release;
export const getReleaseQualifiers = (state: IReduxState) => state.releaseReducer.release.all_qualifiers || [];
export const getReleaseId = (state: IReduxState) => state.releaseReducer.release.id;
export const getIsFetching = (state: IReduxState) => state.releaseReducer.isFetching;
export const getSeanceDates = (state: IReduxState) => state.releaseReducer.calendar.seance_dates;
export const getCinemas = (state: IReduxState) => state.releaseReducer.cinemas;
export const getReleaseError = (state: IReduxState) => state.releaseReducer.error;

export const getHasSpecificReleaseItemQualifier = (release: IRelease, qualifier: string) => {
	return release && (release.all_qualifiers || []).some(value => value === qualifier);
};

export const isProCultureReleaseItem = createSelector(
	[getReleaseSelector],
	isProCultureRelease
);

export const getIsBadgeShow = createSelector(
	[getReleaseQualifiers], qualifiers => {
	const allowedQualifiers = [
		Qualifiers.premiere,
		Qualifiers.pro_culture,
		Qualifiers.closed_show,
		Qualifiers.special_show,
		Qualifiers.stellar_premiere
	];

	return allowedQualifiers.some(allowedQualifier => qualifiers.includes(allowedQualifier));
})
