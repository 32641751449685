import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import AfishaComponent from 'app/components/afisha/AfishaComponent';

type Props = RouteComponentProps & {
	addedPath: string,
};

const ProcultureAfishaPageComponent = ({addedPath}: Props) => {
	return (
		<Switch>
			<Route path={`${addedPath}/pushkin_card`}>
				<AfishaComponent isProCulture />
			</Route>
		</Switch>
	);
};

export default withRouter(ProcultureAfishaPageComponent);
