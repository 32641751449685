import React from 'react';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import PropTypes from 'prop-types';
import {CinemaPropType} from 'app/constants/AppConstants';
import {setYaReachGoal} from 'app/selectors/Helpers';

export default class SmartBannerComponent extends React.Component {
	static propTypes = {
		apps: PropTypes.shape({
			appstore: PropTypes.string,
			googleplay: PropTypes.string
		}).isRequired,
		cinema: CinemaPropType.isRequired,
		closeSmartBanner: PropTypes.func.isRequired,
		isKinokassaApp: PropTypes.bool.isRequired
	};

	handleClick = () => {
		const {googleplay} = this.props.apps;
		window.open(googleplay);
		setYaReachGoal('googleplay-click');
	};

	handleClose = () => {
		this.props.closeSmartBanner();
		localStorage.setItem('smart', 'close');
		setYaReachGoal('cinemasite-hide-app', null, false);
	};

	render() {
		const {isKinokassaApp, cinema: {title}} = this.props;
		const image = isKinokassaApp ? 'img/kinokassa-icon.png' : 'img/app-icon.png';
		const text = isKinokassaApp
			? `${i18n.t('Kinokassa')}: ${i18n.t('ticketsAtCinema')}`
			: `${i18n.t('Cinema')} ${title || ''}`;

		return (
			<div className="smart">
				<button className="smart__close" onClick={this.handleClose}>
					<MdClose size={16} />
				</button>
				<img src={image} alt="" />
				<div className="smart__cinema">
					{text} <br />
					<span className="smart__store">Google Play</span>
				</div>
				<button
					className="button button--download"
					onClick={this.handleClick}
				>
					{i18n.t('Download')}
				</button>
			</div>
		);
	}
}
