import React from 'react';
import PropTypes from 'prop-types';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {arrowIcon} from 'app/constants/IconsConstants';
import ReleaseItem from 'app/containers/ReleaseItemContainer';
import {scrollToTop, setYaReachGoal} from 'app/selectors/Helpers';
import {FiltersPage} from 'app/modules/filters/types';
import NoSeances from 'app/components/NoSeancesComponent';
import FiltersWrapper from "app/modules/filters/components/FiltersWrapper";
import { NoSeancesReason } from 'app/types/seances';

export default class ReleasesSoonComponent extends React.Component {
	static propTypes = {
		getReleasesSoon: PropTypes.func.isRequired,
		setCurrentFiltersPage: PropTypes.func.isRequired,
		isFiltersActive: PropTypes.bool,
		isFetching: PropTypes.bool,
		releasesSoon: PropTypes.arrayOf(PropTypes.object).isRequired,
		resetReleaseItemState: PropTypes.func.isRequired,
		soonPageIsEnabled: PropTypes.bool.isRequired,
		page: PropTypes.string.isRequired,
		isReleasesSoonPage: PropTypes.bool,
		cityId: PropTypes.number,
		prefix: PropTypes.string.isRequired
	};

	static defaultProps = {
		isReleasesSoonPage: false
	};

	componentDidMount() {
		const {getReleasesSoon, releasesSoon, cityId, setCurrentFiltersPage, isReleasesSoonPage} = this.props;

		if (isReleasesSoonPage) {
			setCurrentFiltersPage(FiltersPage.SOON);
		}

		if (!releasesSoon.length) {
			getReleasesSoon(cityId);
		}
		scrollToTop();
	}

	componentWillUnmount() {
		this.props.resetReleaseItemState();
	}

	render() {
		const {
			releasesSoon, isReleasesSoonPage, soonPageIsEnabled,
			page, prefix, isFetching, isFiltersActive
		} = this.props;

		let body = null;

		switch (true) {
			case !!releasesSoon.length:
				body = (
					<div
						id="releasesSoon"
						className={`releases-soon ${isReleasesSoonPage && soonPageIsEnabled ? '' : 'releases-soon__mini'}`}
					>
						<div className="container releases-soon--title">
							<h2 className="releases-list__title">{i18n.t('SoonAtCinemas')}</h2>
							{!isReleasesSoonPage && soonPageIsEnabled &&
								<div className="releases-soon--title__container">
									<KeydownEnterLink
										to={`${prefix}/soon`}
										className="releases-soon--title__link"
										onClick={() => setYaReachGoal(`${page}-showall-click`, null, false)}
									>
										{i18n.t('ShowAll')}
									</KeydownEnterLink>
									{arrowIcon}
								</div>
							}
						</div>
						<div className="container releases-container">
							{releasesSoon.map((release, index) =>
								<ReleaseItem release={release} key={index} soon page={page} />,
							)}
						</div>
					</div>
				);
				break;
			case isReleasesSoonPage:
				body = (
					<NoSeances
						type={isFiltersActive ? NoSeancesReason.NO_SEANCES_FOR_SELECTED_FILTERS : NoSeancesReason.NO_SEANCES_SOON}
					/>
				);
				break;
		}

		return (
			<div>
				{isReleasesSoonPage && <FiltersWrapper isDisabled={isFetching} />}
				{body}
			</div>
		);
	}
}
